<template>
    <div class="wrapper">
        <Html :lang="locale" />

        <Header />
        <main>
            <slot />
        </main>
        <Footer />

        <ScrollTop />

        <Notification group="notification" />
        <UploaderFileProgress />
    </div>
</template>

<script setup lang="ts">
import Header from './components/Header/index.vue';
import Footer from './components/Footer/index.vue';
import ScrollTop from './components/ScrollTop.vue';
import Notification from '@c/Notification.vue';
import UploaderFileProgress from '@c/UploaderFile/Progress.vue';

/** Data **/
const { locale } = useI18n();
</script>

<style scoped lang="scss">
.wrapper {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
}
</style>

<style lang="scss">
@use "assets/styles/variables" as *;

.p-scrolltop {
    transition: $transition;
    background-color: $color-primary-lighter-2;
    filter: drop-shadow(0 1px 5px rgba(4, 27, 45, 0.15)) drop-shadow(0 1px 5px rgba(4, 27, 45, 0.15));

    &:hover {
        background-color: $color-primary-lighter;
        filter: drop-shadow(0 2px 3px rgba(4, 27, 45, 0.3)) drop-shadow(0 6px 10px rgba(4, 27, 45, 0.15));
    }
}
</style>
